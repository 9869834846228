@import url('https://rsms.me/inter/inter.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-shadow-md {
    text-shadow: 
      0 0 10px rgba(255, 165, 0, 0.5), 
      0 0 20px rgba(255, 165, 0, 0.4), 
      0 0 30px rgba(255, 165, 0, 0.3),
      -10px 0 10px rgba(255, 165, 0, 0.5), 
      10px 0 10px rgba(255, 165, 0, 0.5), 
      0 -10px 10px rgba(255, 165, 0, 0.5), 
      0 10px 10px rgba(255, 165, 0, 0.5);
  }
  
  .text-shadow-lg {
    text-shadow: 
      0 0 20px rgba(255, 165, 0, 0.5), 
      0 0 40px rgba(255, 165, 0, 0.4), 
      0 0 60px rgba(255, 165, 0, 0.3),
      -20px 0 20px rgba(255, 165, 0, 0.5), 
      20px 0 20px rgba(255, 165, 0, 0.5), 
      0 -20px 20px rgba(255, 165, 0, 0.5), 
      0 20px 20px rgba(255, 165, 0, 0.5);
  }
  .text-shadow-none {
    text-shadow: none;
  }
}



* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Helvetica', 'Inter', sans-serif;
  background: #403fb7;
}

a {
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}